.banner-img {
    width: 50%;
    margin-bottom: 1.25rem;
    margin-top: 1.25rem;
}

.upload-box {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
    text-align: center;
    border-radius: 0.5rem;
    border-width: 4px;
    border-style: dashed;
    width: 100%;
}

.center {
    display: "flex";
    justify-content: "center";
    align-items: "center"
}

.date-box {
    display: "block";
    padding-top: "0.375rem";
    padding-bottom: "0.375rem";
    padding-left: "0.75rem";
    padding-right: "0.75rem";
    margin: "0";
    background-clip: "padding-box";
    background-color: "#ffffff";
    transition-property:
      "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform";
    /* transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)", */
    color: #374151;
    font-size: "1rem";
    line-height: "1.5rem";
    font-weight: "400";
    width: 100%;
    border-radius: "0.25rem";
    border-width: "1px";
    border-color: #D1D5DB;
    border-style: "solid";
}